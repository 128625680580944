<template>
  <div>
    <ValidationObserver ref="observer" v-slot="{ passes }">
      <form @submit.prevent="passes(recordAttendance)">
        <div class="columns is-multiline">
          <div class="column is-10 is-offset-1">
            <div class="columns is-multiline">
              <div class="column is-12">
                <SdSelect
                  rules="required"
                  label="School Class"
                  v-model="schoolClass"
                  expanded
                >
                  <option
                    v-for="schoolClass in schoolClasses"
                    :key="schoolClass.id"
                  >
                    {{ schoolClass.name }}
                  </option>
                </SdSelect>
              </div>
              <div class="column is-12">
                <template v-if="loading">
                  <loading />
                </template>
                <template v-else>
                  <b-table
                    :data="studentsInClass"
                    :checked-rows.sync="checkedStudents"
                    checkable
                    hoverable
                  >
                    <b-table-column
                      field="#"
                      label="#"
                      width="40"
                      numeric
                      v-slot="props"
                      >{{
                        studentsInClass.indexOf(props.row) + 1
                      }}</b-table-column
                    >

                    <b-table-column label="RegId" v-slot="props">{{
                      props.row.regId.toUpperCase()
                    }}</b-table-column>

                    <b-table-column label="Name" v-slot="props">{{
                      props.row.name
                    }}</b-table-column>
                  </b-table>
                </template>
              </div>
              <div class="column is-12">
                <SdInput
                  type="text"
                  rules="required"
                  label="Total checked"
                  v-model="checkedStudents.length"
                  placeholder="Total checked"
                  readonly
                />
              </div>
              <div class="column is-12">
                <b-field label="Select a date">
                  <b-datepicker
                    v-model="attendanceDate"
                    :show-week-number="showWeekNumber"
                    :locale="locale"
                    :unselectable-days-of-week="[0, 6]"
                    placeholder="Click to select..."
                    icon="calendar"
                    trap-focus
                  >
                  </b-datepicker>
                </b-field>
              </div>
              <div class="column is-12">
                <div class="buttons is-right">
                  <button class="button is-success">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import SCHOOL_CLASSES from '../../../graphql/school_class/SchoolClasses.gql'
import TERMS from '../../../graphql/term/Terms.gql'
import SdInput from '../../components/SdInput'
import SdSelect from '../../components/SdSelect'
import { crudNotification } from '../../assets/js/notification'
import { ValidationObserver } from 'vee-validate'

export default {
  data() {
    return {
      schoolId: null,
      pageTitle: 'Attendance',
      term: null,
      termId: null,
      terms: [],
      schoolClass: null,
      schoolClassId: null,
      schoolClasses: [],
      averageScores: [],
      loading: false,
      studentsInClass: [],
      checkedStudents: [],
      attendanceDate: new Date(),
      showWeekNumber: false,
      locale: undefined,
    }
  },
  apollo: {
    terms: {
      query: TERMS,
      variables() {
        return { schoolId: this.schoolId }
      },
    },
    schoolClasses: {
      query: SCHOOL_CLASSES,
      variables() {
        return { schoolId: this.schoolId }
      },
    },
  },
  watch: {
    term(value) {
      this.termId = this.terms.filter((term) => term.title === value)[0].id
    },
    schoolClass(value) {
      this.checkedStudents = []
      this.schoolClassId = this.schoolClasses.filter(
        (schoolClass) => schoolClass.name === value
      )[0].id
      this.fetchStudentsInClass()
    },
  },
  computed: {
    selectedString() {
      return this.attendanceDate ? this.attendanceDate.toDateString() : ''
    },
  },
  components: {
    SdInput,
    SdSelect,
    ValidationObserver,
  },
  mounted() {
    if (this.$route.params.id !== 'new') this.skipQuery = false
    this.schoolId = parseInt(this.$route.params.school_id)
    this.schoolClassId = parseInt(this.$route.params.id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      {
        name: 'Attendances',
        route: `/school/${this.schoolId}/attendances`,
      },
    ])
  },
  methods: {
    fetchStudentsInClass() {
      this.loading = true
      this.$apollo
        .query({
          query: gql`
            query studentsInClass($schoolClassId: Int!) {
              studentsInClass(schoolClassId: $schoolClassId) {
                id
                regId
                name
              }
            }
          `,
          variables: {
            schoolClassId: parseInt(this.schoolClassId),
          },
        })
        .then((response) => {
          this.studentsInClass = response.data.studentsInClass
          this.loading = false
        })
        .catch((error) => {
          console.error(error)
        })
    },
    recordAttendance() {
      const year = new Date().getFullYear()
      const month = `${this.attendanceDate.getMonth()}`
      const day = `${this.attendanceDate.getDate()}`

      const term = this.$store.getters.getTerm

      // Check date
      const currentDate = new Date(year, month, day)
      const beginDate = new Date(term.begin_date)
      const endDate = new Date(term.end_date)

      console.log(currentDate, beginDate, endDate)

      if (currentDate < beginDate || currentDate > endDate) {
        this.$buefy.notification.open({
          duration: 5000,
          message: 'Attendance date is out of range',
          position: 'is-top',
          type: 'is-danger',
          hasIcon: true,
        })
        return
      }

      const studentIds = this.checkedStudents.map((student) => student.id)

      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateAttendanceQuery(
              $schoolClassId: Int!
              $studentIds: [String!]!
              $month: String!
              $day: String!
            ) {
              updateAttendance(
                input: {
                  schoolClassId: $schoolClassId
                  studentIds: $studentIds
                  month: $month
                  day: $day
                }
              ) {
                schoolClass {
                  id
                }
                errors
              }
            }
          `,
          variables: {
            schoolClassId: parseInt(this.schoolClassId),
            studentIds: studentIds,
            month: `${this.attendanceDate.getMonth() + 1}`,
            day: `${this.attendanceDate.getDate()}`,
          },
        })
        .then((response) => {
          crudNotification(
            response.data.updateAttendance.errors,
            'Successfully updated.'
          ).then(() => {
            this.$router.push(
              `/school/${this.$route.params.school_id}/attendances`
            )
          })
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
}
</script>
